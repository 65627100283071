<template>
  <div class="admin">

    <div class="admin-area">

      <div class="row row-gutter-20">
        <div class="col-6">
          <admin-nav></admin-nav>
        </div>
        <div class="col-18">

          <header class="admin-header cf">
            <ul class="nav nav-header cf">
              <li><router-link to="/admin/vehicle_categories">Kategorien</router-link></li>
              <li><router-link :to="'/admin/vehicle_categories/'+this.$route.params.id">{{ vehicle_category.name }}</router-link></li>
              <li>Preise</li>
            </ul>
            <div class="header-actions">
            </div>
          </header>

          <ul class="nav nav-tabs cf">
            <li><router-link :to="'/admin/vehicle_categories/'+this.$route.params.id">Stammdaten</router-link></li>
            <li class="active"><router-link :to="'/admin/vehicle_categories/'+this.$route.params.id+'/prices'">Preise</router-link></li>
            <li><router-link :to="'/admin/vehicle_categories/'+this.$route.params.id+'/extras'">Extras</router-link></li>
          </ul>

          <div class="admin-body">

            <div class="well">
              <form @submit="update_vehicle_category" class="form" method="post">

                <div class="row row-gutter-20">
                  <div class="col-12">
                    <div class="form-wrap">
                      <label for="price_per_day">Basispreis</label>
                      <input v-model="vehicle_category.price_per_day" class="form-input" type="text" name="price_per_day" id="price_per_day" placeholder="29.99">
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-wrap">
                      <label for="price_discounted">Rabattierter Preis</label>
                      <input v-model="vehicle_category.price_discounted" class="form-input" type="text" name="price_discounted" id="price_discounted" placeholder="29.99">
                    </div>
                  </div>
                </div>

                <p style="margin-top:0;margin-bottom:0">
                  <input type="submit" value="Basispreis speichern ›" class="button button-orange button-small">
                </p>

              </form>
            </div>

            <div class="well">
              <h3>Preise pro Wochentag</h3>

              <form @submit="update_vehicle_category" class="form" method="post">

                <div class="row row-gutter-20">
                  <div class="col-3">
                    <div class="form-wrap">
                      <label for="price_monday">Montag</label>
                      <input v-model="vehicle_category.price_monday" class="form-input" type="text" name="price_monday" id="price_monday" placeholder="29.99">
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-wrap">
                      <label for="price_tuesday">Dienstag</label>
                      <input v-model="vehicle_category.price_tuesday" class="form-input" type="text" name="price_tuesday" id="price_tuesday" placeholder="29.99">
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-wrap">
                      <label for="price_wednesday">Mittwoch</label>
                      <input v-model="vehicle_category.price_wednesday" class="form-input" type="text" name="price_wednesday" id="price_wednesday" placeholder="29.99">
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-wrap">
                      <label for="price_thursday">Donnerstag</label>
                      <input v-model="vehicle_category.price_thursday" class="form-input" type="text" name="price_thursday" id="price_thursday" placeholder="29.99">
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-wrap">
                      <label for="price_friday">Freitag</label>
                      <input v-model="vehicle_category.price_friday" class="form-input" type="text" name="price_friday" id="price_friday" placeholder="29.99">
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-wrap">
                      <label for="price_saturday">Samstag</label>
                      <input v-model="vehicle_category.price_saturday" class="form-input" type="text" name="price_saturday" id="price_saturday" placeholder="29.99">
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-wrap">
                      <label for="price_sunday">Sonntag</label>
                      <input v-model="vehicle_category.price_sunday" class="form-input" type="text" name="price_sunday" id="price_sunday" placeholder="29.99">
                    </div>
                  </div>
                </div>

                <p style="margin-top:0;margin-bottom:0">
                  <input type="submit" value="Wochenpreise speichern ›" class="button button-orange button-small">
                </p>

              </form>
            </div>

            <div class="well">
              <h3>Preise bei längerer Nutzung</h3>

              <form @submit="update_vehicle_category" class="form" method="post">

                <div class="row row-gutter-20">
                  <div class="col-4">
                    <div class="form-wrap">
                      <label for="price_lt_2">2 Tage</label>
                      <input v-model="vehicle_category.price_lt_2" class="form-input" type="text" name="price_lt_2" id="price_lt_2" placeholder="29.99">
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-wrap">
                      <label for="price_lt_3">3 Tage</label>
                      <input v-model="vehicle_category.price_lt_3" class="form-input" type="text" name="price_lt_3" id="price_lt_3" placeholder="29.99">
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-wrap">
                      <label for="price_lt_5">5 Tage</label>
                      <input v-model="vehicle_category.price_lt_5" class="form-input" type="text" name="price_lt_5" id="price_lt_5" placeholder="29.99">
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-wrap">
                      <label for="price_lt_7">7 Tage</label>
                      <input v-model="vehicle_category.price_lt_7" class="form-input" type="text" name="price_lt_7" id="price_lt_7" placeholder="29.99">
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-wrap">
                      <label for="price_lt_14">14 Tage</label>
                      <input v-model="vehicle_category.price_lt_14" class="form-input" type="text" name="price_lt_14" id="price_lt_14" placeholder="29.99">
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-wrap">
                      <label for="price_lt_30">1 Monat</label>
                      <input v-model="vehicle_category.price_lt_30" class="form-input" type="text" name="price_lt_30" id="price_lt_30" placeholder="29.99">
                    </div>
                  </div>
                </div>

                <p style="margin-top:0;margin-bottom:0">
                  <input type="submit" value="Preise speichern ›" class="button button-orange button-small">
                </p>

              </form>

            </div>

            <div class="well">
              <h3>Wochenendzuschlag</h3>

              <form @submit="update_vehicle_category" class="form" method="post">

                <div class="row row-gutter-20">
                  <div class="col-12">
                    <div class="form-wrap">
                      <label for="price_lt_2">2 Tage, z. B. Samstag und Sonntag.<br>Es wird mit folgendem Wert multipliziert.</label>
                      <input v-model="vehicle_category.price_ws_2" class="form-input" type="text" name="price_lt_2" id="price_lt_2" placeholder="1.3">
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-wrap">
                      <label for="price_lt_2">3 Tage, z. B. Freitag bis Sonntag.<br>Es wird mit folgendem Wert multipliziert.</label>
                      <input v-model="vehicle_category.price_ws_3" class="form-input" type="text" name="price_lt_3" id="price_lt_3" placeholder="1.3">
                    </div>
                  </div>
                </div>

                <p style="margin-top:0;margin-bottom:0">
                  <input type="submit" value="Preise speichern ›" class="button button-orange button-small">
                </p>

              </form>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import AdminNav from '../../../../components/admin_nav'

export default {
  name: 'admin_vehicle_category_prices',
  components: {
    AdminNav
  },
  data () {
    return {
      errors: [],
      vehicle_category: {},
      prices: {}
    }
  },
  methods: {
    get_data() {

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/vehicle_categories/'+this.$route.params.id, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.vehicle_category = response.data.vehicle_category;
      })
      .catch(error => {
        console.log(error);
      })

    },
    update_vehicle_category: function (e) {
      e.preventDefault();

      axios.patch(process.env.VUE_APP_BASE_API+'/v1/admin/vehicle_categories/'+this.$route.params.id, {
        'price_per_day': this.vehicle_category.price_per_day,
        'price_discounted': this.vehicle_category.price_discounted,
        'price_monday': this.vehicle_category.price_monday,
        'price_tuesday': this.vehicle_category.price_tuesday,
        'price_wednesday': this.vehicle_category.price_wednesday,
        'price_thursday': this.vehicle_category.price_thursday,
        'price_friday': this.vehicle_category.price_friday,
        'price_saturday': this.vehicle_category.price_saturday,
        'price_sunday': this.vehicle_category.price_sunday,
        'price_lt_2': this.vehicle_category.price_lt_2,
        'price_lt_3': this.vehicle_category.price_lt_3,
        'price_lt_5': this.vehicle_category.price_lt_5,
        'price_lt_7': this.vehicle_category.price_lt_7,
        'price_lt_14': this.vehicle_category.price_lt_14,
        'price_lt_30': this.vehicle_category.price_lt_30,
        'price_ws_2': this.vehicle_category.price_ws_2,
        'price_ws_3': this.vehicle_category.price_ws_3,
      }, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.$notify({
          group: 'alert',
          type: 'success',
          title: 'Erfolgreich gespeichert.'
        });
        this.$router.push('/admin/vehicle_categories/'+this.$route.params.id+'/prices');
      })
      .catch(error => {
        error.response.data.errors.forEach(val => {
          this.$notify({
            group: 'alert',
            type: 'error',
            title: 'Fehler',
            text: val
          });
        })
      })

    }
  },
  mounted () {
    this.get_data();
  }
}
</script>
